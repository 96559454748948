import React from 'react'
import './style.less';
import { Row, Col, Carousel } from 'antd';
import GoBack from "../GoBack";
import Img from "gatsby-image";

class PageHeader extends React.Component {

    render() {

        const settings = {
            dots: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            fade: true,
            swipeToSlide: true,
        };

        let count = 0;

        if(this.props.videoID) {
            return (
                <div className="page-header video slider" data-depth={this.props.depth}>
                    <Carousel className="header-slider" {...settings}>
                        <div className="slide video" key={'slide_' + count}>
                            <iframe title={"video-banner-"+this.props.videoID}
                                src={"https://player.vimeo.com/video/"+this.props.videoID+"?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;autopause=0&amp;muted=1&amp;background=1&amp;loop=1"}
                                frameBorder="0"
                            />
                        </div>
                        {this.props.slides && this.props.slides.map((image) => {
                            count++;
                            return <div className="slide" key={'slide_' + count}><Img className="slide-image" fluid={image}/></div>
                        })}
                    </Carousel>
                    <Row gutter={70}>
                        <Col xs={{span: 24}} data-color={this.props.headerColor} data-style={this.props.headerStyle}>
                            <h1 dangerouslySetInnerHTML={{__html: this.props.title}}/>
                            {this.props.back !== '' &&
                                <GoBack/>
                            }
                            {this.props.date !== '' &&
                                <span className="date">
                                    <span className="clock" />
                                    {this.props.date}
                                </span>
                            }
                        </Col>
                    </Row>
                </div>
            );
        } else if(this.props.slides) {
            return (
                <div className="page-header slider" data-depth={this.props.depth}>
                    <Carousel className="header-slider" {...settings}>
                        {this.props.slides && this.props.slides.map((image) => {
                            count++
                            return <div className="slide" key={'slide_' + count}><Img className="slide-image" fluid={image}/></div>
                        })}
                    </Carousel>
                    <Row gutter={70}>
                        <Col xs={{span: 24}} data-color={this.props.headerColor} data-style={this.props.headerStyle}>
                            <h1 dangerouslySetInnerHTML={{__html: this.props.title}}/>
                            {this.props.back !== '' &&
                                <GoBack/>
                            }
                            {this.props.date !== '' &&
                                <span className="date">
                                    <span className="clock" />
                                    {this.props.date}
                                </span>
                            }
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (
                <div className="page-header image" data-depth={this.props.depth}>
                    <Img className="spacer" fluid={this.props.image}/>
                    <Row gutter={70}>
                        <Col xs={{span: 24}} data-color={this.props.headerColor} data-style={this.props.headerStyle}>
                            <h1 dangerouslySetInnerHTML={{__html: this.props.title}}/>
                            {this.props.back !== '' &&
                                <GoBack/>
                            }
                            {this.props.date !== '' &&
                                <span className="date">
                                    <span className="clock" />
                                    {this.props.date}
                                </span>
                            }
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

export default PageHeader