import React from 'react';
import {LocaleContext} from "../../context/LocaleContext";
import language from "../../../data/language.yaml";

export default class GoBack extends React.Component {

    back(){
        window.history.back()
    }

    render() {
        return (
            <button className="go-back" onClick={() => this.back()}>{language.back[this.context.locale]}</button>
        );
    }
}

GoBack.contextType = LocaleContext;
