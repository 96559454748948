import React from 'react';
import Link from "../Link";
import { StaticQuery, graphql } from "gatsby";

class FootCol2English extends React.Component {


    render() {

        return (
            <div>
                {this.props.links.map((link) => {
                    // add a trailing slash
                    if (link.url.substr(-1) !== '/') link.url += '/';
                    return <Link className="footer-link" to={link.url}  key={link.wordpress_id} dangerouslySetInnerHTML={{__html: link.title}} />
                })}
            </div>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query FootCol2ItemLinksEnglish {
                allWordpressWpApiMenusMenusItems (filter: {slug: {eq:"footer-menu-column-2-english"}}){
                    edges {
                        node {
                            id
                            slug
                            description
                            wordpress_id
                            items {
                                title
                                url
                                target
                                classes
                                order
                                wordpress_id
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <FootCol2English links={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
        )}
    />
)
