import React from 'react'
import './style.less';
import {Icon} from 'react-fa';
import { Layout, Row, Col } from 'antd';
import Link from "../Link";
import S360Logo from "../S360Logo";

import FootCol1English from "../FootCol1English";
import FootCol2English from "../FootCol2English";
import FootCol1Welsh from "../FootCol1Welsh";
import FootCol2Welsh from "../FootCol2Welsh";
import lotusLogo from '../../images/lotus-logo@2x.png';
import logo from '../../images/logo-png-for-website1@2x.png';

import language from "../../../data/language.yaml";

const { Footer } = Layout;

class Foot extends React.Component {

    render(){

        return (
            <Footer>
                <Row type={"flex"} gutter={50}>
                    <Col xs={{span: 24}}>
                        {this.props.locale === 'en' &&
                            <Link className="logo" to="/" width="120">
                                <S360Logo logoStyle={"small"}/>
                            </Link>
                        }
                        {this.props.locale === 'cy' &&
                            <Link className="logo" to="/cy/" width="120">
                                <S360Logo logoStyle={"small"}/>
                            </Link>
                        }
                        <p className="copy">&copy; {language.footer.copyright[this.props.locale]}</p>
                        <p className="credit">{language.footer.companyRegistrationNumber[this.props.locale]}: 07620063</p>
                    </Col>
                    <Col xs={{span: 12}} md={{span: 8}} lg={{span: 6}}>
                        <h4>{language.footer.headers.information[this.props.locale]}</h4>
                        {this.props.locale === 'en' &&
                            <FootCol1English />
                        }
                        {this.props.locale === 'cy' &&
                            <FootCol1Welsh />
                        }
                    </Col>
                    <Col xs={{span: 12}} md={{span: 8}} lg={{span: 6}}>
                        <h4>{language.footer.headers.getInTouch[this.props.locale]}</h4>
                        {this.props.locale === 'en' &&
                            <FootCol2English />
                        }
                        {this.props.locale === 'cy' &&
                            <FootCol2Welsh />
                        }
                    </Col>
                    <Col xs={{span: 24}} md={{span: 8}} lg={{span: 6}}>
                        <Row type={"flex"} gutter={70}>
                            <Col xs={{span: 12}} md={{span: 24}}>
                                <h4>{language.footer.headers.sponsoredBy[this.props.locale]}</h4>
                                <Link to={language.footer.partnersLink[this.props.locale]}><img src={lotusLogo} alt="Lotus"/></Link>
                            </Col>
                            <Col xs={{span: 12}} md={{span: 24}}>
                                <h4>{language.footer.headers.supportersOf[this.props.locale]}</h4>
                                <Link to={language.footer.partnersLink[this.props.locale]}><img src={logo} alt="Cymdeithas Eryri Snowdonia Society"/></Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 6}}>
                        <h4>{language.footer.headers.stayInTouch[this.props.locale]}</h4>
                        <Link className="btn btn-primary" to={language.footer.signUp.buttonLink[this.props.locale]}>
                            <span>{language.footer.signUp.buttonText[this.props.locale]}</span>
                            <span className="icon arrow"></span>
                        </Link>

                        <h4>{language.footer.headers.wereSocial[this.props.locale]}</h4>
                        <Link className="social-link" to="https://www.instagram.com/snowdonia360/">
                            <Icon name="instagram" size={"2x"}/>
                        </Link>
                        <Link className="social-link" to="https://twitter.com/mysnowdonia360">
                            <Icon name="twitter" size={"2x"} />
                        </Link>
                        <Link className="social-link" to="https://www.facebook.com/snowdonia360/">
                            <Icon name="facebook" size={"2x"} />
                        </Link>
                    </Col>
                    <Col xs={{span:24}}>
                        <p><small><Link to="https://www.wearebrew.co.uk">{language.footer.builtBy[this.props.locale]} Brew</Link></small></p>
                    </Col>
                </Row>

            </Footer>
        );
    }
}

export default Foot
